// Functions for social share link clicks

'use strict';

// Record GA4 data for click on any share link
function recordShareAnalytics(platform, type, slug) {
  if (window.gtag) {
    gtag('event', 'share', {
      content_type: type,
      item_id: slug,
      method: platform,
    });
  }
}

// Record GA4 data for share button click
function recordSharingIntentAnalytics() {
  if (window.gtag) {
    gtag('event', 'open_share_options');
  }
}
